import ItemIcon from 'components/professions/ItemIcon'
import { getEnchantingLevelLabel } from 'helpers/items'

export default function BagSlot ({ bagSlot, disabled }) {
  const quantity = bagSlot.user_item.item.type === 'fail_stack' ? bagSlot?.user_item?.durability : bagSlot.quantity

  return (
    <span className={`w-full relative block ${disabled ? 'opacity-20' : ''}`}>
      <ItemIcon
        item={bagSlot.user_item.item}
        userItem={bagSlot.user_item}
        quantity={quantity}
      />
      {bagSlot.user_item.item.type === 'gear' && (
        <>
          <div className="min-w-4 h-4 bg-white text-black font-bold text-sm flex justify-center items-center rounded-sm absolute -top-[2px] -left-[2px]">
            {getEnchantingLevelLabel(bagSlot.user_item.enchanting_category, bagSlot.user_item.enchanting_level)}
          </div>
        </>
      )}
    </span>
  )
}
