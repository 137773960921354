import Link from 'next/link'
import Button from '@atoms/Button'
import NavList from './NavList'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faArrowRight } from '@fortawesome/pro-solid-svg-icons'
import { useDispatch, useSelector } from 'react-redux'
import { faGear, faRightToBracket } from '@fortawesome/pro-duotone-svg-icons'
import { logOut } from 'store'
import { useRouter } from 'next/router'
import axios from '../../helpers/api'
import { Tooltip } from 'react-tippy'
import ButtonLink from '@atoms/ButtonLink'
import NavListItem from './NavListItem'

export default function DesktopNav ({ onToggle, isTavern }) {
  const dispatch = useDispatch()
  const user = useSelector(store => store.main.auth.user)
  const menuIsOpen = useSelector(state => state.main.menu.isOpen)
  const router = useRouter()
  const logout = () => {
    axios.put('/api/auth/logout').finally(() => {
      dispatch(logOut())
      router.push('/')
    })
  }

  return (
    <div className="relative hidden lg:block">
      <span className="hidden lg:block absolute top-0 left-0 pointer-events-none rounded-br-sm bg-purple-600 text-xs uppercase h-auto font-semibold leading-0 px-1.5 py-0.5 ml-auto">
        Beyond God&apos;s Trench - Alpha Pre Build
      </span>
      <header className={`bg-dark border-r border-primary h-full p-10 px-8 overflow-x-hidden lg:overflow-y-auto ${menuIsOpen ? 'p-10' : 'px-6'}`}>
        <div className="flex flex-col lg:h-full">
          <Link
            href={user ? '/tavern' : ''}
            legacyBehavior
          >
            <a className="block mb-8">
              <img
                src={menuIsOpen ? '/ale-and-axes-full-logo.png' : '/tavern-flame.png'}
                alt="Tavern Squad logo"
                className={menuIsOpen ? 'h-20' : 'w-8 mx-auto'}
              />
            </a>
          </Link>

          <NavList
            iconsOnly={!menuIsOpen}
            className="my-6"
          />

          <div className="mt-auto">
            {!user && (
            <Tooltip
              title="Login"
              disabled={menuIsOpen}
            >
              <ButtonLink
                className="w-full flex align-center justify-center"
                href="/login"
                raids
              >
                <FontAwesomeIcon
                  icon={faRightToBracket}
                  className={`inline-block w-4 h-4 ${menuIsOpen ? 'mr-1' : ''}`}
                />
                <span className={`${menuIsOpen ? '' : 'hidden'}`}>
                  {' '}
                  Login
                </span>
              </ButtonLink>
            </Tooltip>
            )}

            {user && (
            <>
              <ul>
                <NavListItem
                  link={{
                    label: 'Settings',
                    icon: faGear,
                    href: '/account/preferences',
                    active: router.asPath.startsWith('/account/preferences') || router.asPath.includes('wallet')
                  }}
                  iconOnly={!menuIsOpen}
                />
              </ul>
              <Tooltip
                title="Logout"
                disabled={menuIsOpen}
              >
                <Button
                  className="w-full flex align-center justify-center mt-2"
                  onClick={logout}
                  secondary
                >
                  <FontAwesomeIcon
                    icon={faRightToBracket}
                    className={`inline-block w-4 h-4 rotate-180 ${menuIsOpen ? 'mr-1' : ''}`}
                  />
                  <span className={`${menuIsOpen ? '' : 'hidden'}`}>
                    {' '}
                    Logout
                  </span>
                </Button>
              </Tooltip>
            </>
            )}
          </div>
        </div>
      </header>

      <Button
        className="absolute flex items-center justify-center rounded-full top-24 -right-2 bg-dark border border-primary w-8 h-8 z-20"
        onClick={onToggle}
      >
        <FontAwesomeIcon
          icon={menuIsOpen ? faArrowLeft : faArrowRight}
          className="w-4 h-4"
        />
      </Button>
    </div>
  )
}
