import Stat from 'components/stats/Stat'
import { twMerge } from 'tailwind-merge'

export default function UserItemStats ({ userItem }) {
  if (!userItem.final_stats) {
    return <></>
  }

  const hasLoweredStats = userItem.durability <= 49

  return (
    <div className={twMerge('font-bold text-white', hasLoweredStats ? 'text-failure' : '')}>
      {userItem.final_stats.map((stat) => {
        return <div key={`stag_slug_${userItem.id}_${stat.stat.slug}`}>
          {stat.value > 0
            ? (
              <Stat
                stat={stat}
                hasLoweredStats={hasLoweredStats}
              />
              )
            : <></>}
        </div>
      })}

      {userItem.durability === 0 && (
        <div className="text-failure">
          This item is broken and lost all its stats. Repair it!
        </div>
      )}
    </div>
  )
}
