import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { checkSupportedWallets } from 'helpers/wallets'
import { NETWORK } from 'consts'
import { connectWallet, disconnectWallet, showWalletOverlay } from 'redux/raids'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRightFromBracket, faCircleNotch, faWallet } from '@fortawesome/pro-duotone-svg-icons'
import { getWalletAssets } from 'helpers/raidsTx'
import { getLucid } from 'helpers/lucid'

export default function WalletSelect () {
  const dispatch = useDispatch()
  const [supported, setSupported] = useState([])
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const selectedWallet = useSelector(store => store.raids.selectedWallet)

  useEffect(() => {
    checkSupportedWallets(false).then(wallets => {
      setSupported(wallets)
      const stored = window.localStorage.getItem('raidsWallet')

      if (wallets.find(w => w.id === stored)) {
        select(stored)
      }
    })
  }, [])

  const select = async wallet => {
    setOpen(false)

    if (wallet === selectedWallet || loading) {
      return
    }

    if (!window.cardano || !window.cardano[wallet]) {
      disconnect()
      return
    }

    setLoading(true)
    const api = await window.cardano[wallet].enable()

    if (await api.getNetworkId() !== NETWORK) {
      disconnect()
      toast.error('Selected wallet is not on the correct network')
      return
    }

    try {
      const lucid = await getLucid()
      lucid.selectWallet(api)
      const assets = await getWalletAssets(lucid)
      const address = await lucid.wallet.address()

      dispatch(connectWallet({
        wallet,
        address,
        ...assets
      }))
      dispatch(showWalletOverlay(false))
      window.localStorage.setItem('raidsWallet', wallet)
    } catch (e) {
      disconnect()
      toast.error(e.message ?? 'Wallet disconnected')
      return
    }

    setLoading(false)
  }

  const disconnect = () => {
    setOpen(false)
    window.localStorage.removeItem('raidsWallet')
    dispatch(disconnectWallet())
    setLoading(false)
  }

  return (
    <div className="relative">
      <button
        type="button"
        className={`relative flex items-center justify-center w-5 h-5 lg:w-[60px] lg:h-[60px] lg:p-5 rounded ${open ? 'bg-raids-darkest rounded-b-none' : 'hover:bg-raids-dark hover:bg-opacity-40'}`}
        onClick={() => setOpen(!open)}
      >
        {loading && (
          <FontAwesomeIcon
            icon={faCircleNotch}
            className="absolute w-3 h-3 top-4 right-4 animate-spin"
          />
        )}

        {!loading && selectedWallet && (
          <img
            src={`/wallets/${selectedWallet}.png`}
            alt={selectedWallet}
            className="absolute w-3 h-3 top-4 right-4"
          />
        )}

        <FontAwesomeIcon
          icon={faWallet}
          className="w-6 h-6"
        />
      </button>

      <div className={`flex absolute z-50 justify-center py-1 lg:py-3 rounded rounded-t-none top-8 lg:top-[60px] -right-1 lg:right-0 bg-raids-darkest w-14 lg:w-[60px] h-80 lg:h-96 ${!open ? 'hidden' : ''}`}>
        <ul className="flex flex-col">
          {supported.map(wallet => (
            <li key={wallet.id}>
              <button
                type="button"
                onClick={() => select(wallet.id)}
                className={`p-3 rounded hover:bg-raids-dark ${wallet.id === selectedWallet ? 'bg-raids-darkest' : ''}`}
              >
                <img
                  src={`/wallets/${wallet.id}.png`}
                  alt={wallet.id}
                  className="block w-6 h-6"
                />
              </button>
            </li>
          ))}

          {selectedWallet && (
            <li className="mt-auto">
              <button
                type="button"
                onClick={disconnect}
                className="p-3 rounded hover:bg-raids-dark"
              >
                <FontAwesomeIcon
                  icon={faArrowRightFromBracket}
                  className="w-6 h-6"
                />
              </button>
            </li>
          )}
        </ul>
      </div>
    </div>
  )
}
