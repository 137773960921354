import { createAction, createReducer } from '@reduxjs/toolkit'

const initialState = {
  parameters: null,
  selectedWallet: null,
  wallet: null,
  adventurerTab: 'info',
  adventurerSearch: '',
  resultRevealed: false,
  error: null,
  showWalletOverlay: false,
  lastClaimedRaid: null,
  sortRaidsBy: null
}

export const setParameters = createAction('raids/setParameters')
export const connectWallet = createAction('raids/connectWallet')
export const updateWallet = createAction('raids/updateWallet')
export const disconnectWallet = createAction('raids/disconnectWallet')
export const adventurerTab = createAction('raids/adventurerTab')
export const adventurerSearch = createAction('raids/adventurerSearch')
export const revealResult = createAction('raids/revealResult')
export const setError = createAction('raids/setError')
export const clearError = createAction('raids/clearError')
export const showWalletOverlay = createAction('raids/showWalletOverlay')
export const setLastClaimedRaid = createAction('raids/setLastClaimedRaid')
export const setSortRaidsBy = createAction('raids/setSortRaidsBy')

export default createReducer(initialState, {
  [setParameters.type]: (state, { payload }) => ({
    ...state,
    parameters: payload
  }),
  [connectWallet.type]: (state, { payload }) => ({
    ...state,
    selectedWallet: payload.wallet,
    wallet: {
      address: payload.address,
      ada: payload.ada,
      adventurers: payload.adventurers,
      coins: payload.coins
    }
  }),
  [updateWallet.type]: (state, { payload }) => ({
    ...state,
    wallet: payload
  }),
  [disconnectWallet.type]: (state) => ({
    ...state,
    selectedWallet: null,
    wallet: null
  }),
  [adventurerTab.type]: (state, { payload }) => ({
    ...state,
    adventurerTab: payload
  }),
  [adventurerSearch.type]: (state, { payload }) => ({
    ...state,
    adventurerSearch: payload
  }),
  [revealResult.type]: (state, { payload }) => ({
    ...state,
    resultRevealed: payload
  }),
  [setError.type]: (state, { payload }) => ({
    ...state,
    error: payload
  }),
  [clearError.type]: state => ({
    ...state,
    error: null
  }),
  [showWalletOverlay.type]: (state, { payload }) => ({
    ...state,
    showWalletOverlay: payload
  }),
  [setLastClaimedRaid.type]: (state, { payload }) => ({
    ...state,
    lastClaimedRaid: payload
  }),
  [setSortRaidsBy.type]: (state, { payload }) => ({
    ...state,
    sortRaidsBy: payload
  })
})
