import { useRouter } from 'next/router'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { csrfReady, setAuthUser } from '../store'
import useApi from './useApi'
import axios from 'helpers/api'

export default function useAuthenticateUser () {
  const isCsrfReady = useSelector(store => store.main.csrfReady)
  const router = useRouter()
  const dispatch = useDispatch()

  useEffect(() => {
    axios.get('/sanctum/csrf-cookie', { withCredentials: false }).then(() => {
      dispatch(csrfReady())
    }).catch(() => {})
  }, [])

  useApi(isCsrfReady && !/^\/discord\/callback/.test(router.asPath) ? '/api/user' : null, {
    onSuccess: data => dispatch(setAuthUser(data)),
    onError: () => dispatch(setAuthUser(false))
  })
}
