import Link from 'next/link'
import PropTypes from 'prop-types'

export default function Avatar ({ user, showLink, border, className, rounded }) {
  const img = (
    <img
      src={user?.avatar}
      alt="User avatar"
      className={`inline-block w-full h-auto ${!user?.avatar ? 'invisible' : ''}`}
      onError={event => { event.target.style.visibility = 'hidden' }}
      width="80"
      height="80"
    />
  )

  return (
    <div className={`block overflow-hidden aspect-square ${rounded ? 'rounded-full' : (!className && !className.includes('rounded') ? 'rounded-tl rounded-bl lg:rounded-none lg:rounded-tl lg:rounded-bl' : '')} bg-tertiary ${border ? 'border border-primary' : ''} ${className}`}>
      {showLink
        ? (
          <Link
            href={`/profiles/${user.discord_id}`}
            legacyBehavior
          >
            <a>
              {img}
            </a>
          </Link>
          )
        : img}

    </div>
  )
}

Avatar.propTypes = {
  user: PropTypes.object.isRequired,
  showLink: PropTypes.bool,
  border: PropTypes.bool,
  className: PropTypes.any
}

Avatar.defaultProps = {
  showLink: false,
  border: true,
  className: ''
}
