import useApi from 'hooks/useApi'
import { APP_VERSION } from 'consts/version'
import RaidOverlay from 'components/raids/RaidOverlay'
import Window from 'components/layout/Window'
import Button from '@atoms/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSparkles } from '@fortawesome/pro-duotone-svg-icons'
import { useRouter } from 'next/router'

export default function VersionCheck () {
  const router = useRouter()
  const { data } = useApi('/api/frontend/info', { refreshInterval: 1000 * 60 * 10 }, false)
  const handleReload = () => {
    router.reload()
  }

  return (
    <div className="relative overflow-hidden">
      {data && data.version && data.version > APP_VERSION && (
        <RaidOverlay>
          <Window
            actions={
              <Button
                onClick={handleReload}
                className="ml-auto"
                raids
              >
                Reload
              </Button>
          }
          >
            <div className="absolute -right-3 -top-3 text-lg font-semibold">
              <img
                src={'/other/barty.jpg'}
                alt="Barty"
                className={'rounded-full w-24 h-24'}
              />
            </div>
            <div className="relative">
              <div className="text-center font-semibold text-lg mt-6 mb-6">
                <FontAwesomeIcon
                  icon={faSparkles}
                  className="w-8 h-8 mb-2 text-primary"
                />
                <br/>
                New version available
              </div>
              <div className="text-center mb-6">
                Hey adventurer, Barty here!
                <br/>
                <br/>
                Goblins have been working hard releasing
                <br/>
                new features, improvements and bug fixes.
                <br/>
                <br/>
                Reload the website to get
                <br/>
                the latest version.
              </div>
            </div>
          </Window>
        </RaidOverlay>
      )}
    </div>
  )
}
