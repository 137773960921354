import * as Sentry from '@sentry/nextjs'
import { toast } from 'react-toastify'
import { ADA_IN_LOVELACE_BIG, COIN_ASSET, COIN_POLICY, CRAFT_FEE, CRAFT_ADDRESS, VENDOR_ADDRESS, SINGLE_WYRMSTONE_BIG, SINGLE_WYRMSTONE, ADA_IN_LOVELACE, ADV_POLICY, SHOP_ADDRESS } from 'consts'
import axios from 'helpers/api'
import { setError } from 'redux/raids'
import { getLucid } from './lucid'

const knownErrors = {
  'No collateral UTxO found': {
    heading: 'No collateral found',
    body: 'We couldn\'t find a collateral UTxO in your wallet, please set one. If you have pending transactions, wait until they complete before trying again.'
  },
  InputsExhaustedError: {
    heading: 'Inputs exhausted',
    body: 'We couldn\'t find enough assets in your wallet. Check if you have enough ADA and/or Wyrmstone in your wallet. If you have pending transactions, wait until they complete before trying again.'
  },
  'Wallet could not send the tx': {
    heading: 'Error sending transaction',
    body: 'We couldn\'t submit your transaction. Please check that the selected adventurers are still in your wallet and not part of pending transactions, and that you have enough ADA and $WYRM to start the raid'
  },
  'user declined tx': null,
  'User declined to sign the transaction': null
}

export function handleError (e, dispatch) {
  const message = e.response?.data?.message || e.message || e.info || (typeof e === 'string' ? e : null) || 'Oops, something went wrong'

  for (const error in knownErrors) {
    if (message.toLowerCase().indexOf(error.toLowerCase()) !== -1) {
      if (knownErrors[error]) {
        dispatch(setError(knownErrors[error]))
      }

      return
    }
  }

  console.error(e)
  toast.error(message)
  Sentry.captureException(e)
}

export async function lock (raid, adventurers, updateMessage) {
  updateMessage('Building your raid')

  await axios.get('/sanctum/csrf-cookie').then(() => {
  }).catch(() => {})

  const response = await axios.post('/api/raids', {
    raid_id: raid.id,
    adventurers
  }).then(({ data }) => {
    return data
  })

  return response
}

export async function claim (userRaid, updateMessage) {
  updateMessage('Ending raid')

  const response = await axios.put(`/api/raids/${userRaid.id}/claim`).then(({ data }) => {
    return data
  })

  return response
}

export async function cancel (userRaid) {
  await axios.put(`/api/raids/${userRaid.id}/cancel`)
}

export async function answerLoreQuestion (userRaid, data) {
  await axios.get('/sanctum/csrf-cookie').then(() => {
  }).catch(() => {})

  const response = await axios.post(`/api/raids/${userRaid.id}/answer-lore-question`, data)
    .then(({ data }) => {
      return data
    })

  return response
}

export async function getWalletAssets (lucid) {
  if (!lucid) {
    return null
  }

  const utxos = await lucid.wallet.getUtxos()

  const ada = utxos.reduce((total, utxo) => total + utxo.assets.lovelace, 0n)

  const coins = utxos.reduce((total, utxo) => {
    return total + Object.keys(utxo.assets).reduce((total, unit) => {
      if (unit === `${COIN_POLICY}${COIN_ASSET}`) {
        total += utxo.assets[unit]
      }

      return total
    }, 0n)
  }, 0n)

  const adventurers = utxos.reduce((array, utxo) => {
    return [
      ...array,
      ...Object.keys(utxo.assets).reduce((array, unit) => {
        if (unit.indexOf(ADV_POLICY) === 0) {
          const assetName = Buffer.from(unit.replace(ADV_POLICY, ''), 'hex').toString()
          array.push(parseInt(assetName.replace(/^TavernSquad0*/, '')))
        }

        return array
      }, [])
    ]
  }, [])

  return {
    coins: parseFloat(`${coins / SINGLE_WYRMSTONE_BIG}`),
    ada: parseFloat(`${ada / ADA_IN_LOVELACE_BIG}.${ada % ADA_IN_LOVELACE_BIG}`),
    adventurers
  }
}

export async function unlockRaidDepth (paymentMethod) {
  const response = await axios.post('/api/raid-unlock-requests', {
    payment_method: paymentMethod
  })

  return response.data
}

export async function craftWyrmstone (amount, updateMessage) {
  updateMessage('Crafting your transaction')

  const lucid = await getLucid()
  const address = await lucid.wallet.address()

  const { data: { request } } = await axios.post('/api/craft-requests', {
    mode: 'to_wyrm',
    amount: parseFloat(amount),
    address
  })

  const tx = await lucid.newTx()
    .payToAddress(CRAFT_ADDRESS, {
      lovelace: CRAFT_FEE
    })
    .complete()

  updateMessage('Waiting for your approval')

  const signedTx = await tx.sign().complete()

  updateMessage('Submitting your transaction')

  const txId = await signedTx.submit()

  const { data } = await axios.put(`/api/craft-requests/${request.id}/start-verification`, {
    tx_id: txId
  })

  return data
}

export async function craftFragments (amount, updateMessage) {
  updateMessage('Crafting your transaction')

  const lucid = await getLucid()
  const address = await lucid.wallet.address()
  amount = Math.round(parseFloat(amount) * 10) / 10

  const { data: { request } } = await axios.post('/api/craft-requests', {
    mode: 'to_fragments',
    amount,
    address
  })

  const tx = await lucid.newTx()
    .payToAddress(CRAFT_ADDRESS, {
      lovelace: CRAFT_FEE,
      [COIN_POLICY + COIN_ASSET]: parseInt(amount * SINGLE_WYRMSTONE)
    })
    .complete()

  updateMessage('Waiting for your approval')

  const signedTx = await tx.sign().complete()

  updateMessage('Submitting your transaction')

  const txId = await signedTx.submit()

  const { data } = await axios.put(`/api/craft-requests/${request.id}/start-verification`, {
    tx_id: txId
  })

  return data
}

export async function purchaseTokenPackage (tokenPackage, updateMessage) {
  updateMessage('Crafting your transaction')

  const lucid = await getLucid()
  const address = await lucid.wallet.address()

  const { data: { request } } = await axios.post('/api/token-package-requests', {
    token_package_id: tokenPackage.id,
    address
  })

  const tx = await lucid.newTx()
    .payToAddress(VENDOR_ADDRESS, {
      lovelace: parseInt(tokenPackage.cost * ADA_IN_LOVELACE)
    })
    .complete()

  updateMessage('Waiting for your approval')

  const signedTx = await tx.sign().complete()

  updateMessage('Submitting your transaction')

  const txId = await signedTx.submit()

  const { data } = await axios.put(`/api/token-package-requests/${request.id}/start-verification`, {
    tx_id: txId
  })

  return data
}

export async function purchaseShopItem (shopItem, updateMessage) {
  updateMessage('Crafting your transaction')

  const lucid = await getLucid()
  const address = await lucid.wallet.address()

  const { data: { shop_purchase: shopPurchase } } = await axios.post('/api/shop-purchases', {
    shop_item_id: shopItem.id,
    address
  })

  const tx = await lucid.newTx()
    .payToAddress(SHOP_ADDRESS, {
      lovelace: parseInt(shopItem.ada_cost * ADA_IN_LOVELACE),
      [COIN_POLICY + COIN_ASSET]: parseInt(shopItem.wyrm_cost * SINGLE_WYRMSTONE)
    })
    .complete()

  updateMessage('Waiting for your approval')

  const signedTx = await tx.sign().complete()

  updateMessage('Submitting your transaction')

  const txId = await signedTx.submit()

  const { data } = await axios.put(`/api/shop-purchases/${shopPurchase.id}/start-verification`, {
    tx_id: txId
  })

  return data
}

export async function mintLog (log, address) {
  await axios.post('/api/mints/log', {
    log,
    address
  })
}
