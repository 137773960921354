import { createAction, createReducer } from '@reduxjs/toolkit'

const initialState = {
  professions: null,
  bag: null,
  bagOpen: false,
  bagTab: 'resource',
  config: null
}

export const setProfessions = createAction('professions/setProfessions')
export const setBag = createAction('professions/setBag')
export const setBagOpen = createAction('professions/setBagOpen')
export const setBagTab = createAction('professions/setBagTab')
export const setConfig = createAction('professions/setConfig')
export const removeFromBag = createAction('professions/removeFromBag')
export const addToBag = createAction('professions/addToBag')

export default createReducer(initialState, {
  [setProfessions.type]: (state, { payload }) => ({
    ...state,
    professions: payload
  }),
  [setBag.type]: (state, { payload }) => ({
    ...state,
    bag: payload
  }),
  [setBagOpen.type]: (state, { payload }) => ({
    ...state,
    bagOpen: payload
  }),
  [setBagTab.type]: (state, { payload }) => ({
    ...state,
    bagTab: payload
  }),
  [setConfig.type]: (state, { payload }) => ({
    ...state,
    config: payload
  }),
  [removeFromBag.type]: (state, { payload }) => {
    const { key } = payload

    Object.keys(state.bag).forEach(type => {
    // Filter out the slot that matches the given key
      state.bag[type].slots = state.bag[type].slots.filter(slot => slot.key !== key)
    })
  },
  [addToBag.type]: (state, { payload }) => {
    const { type, userItem, quantity } = payload

    console.log({
      key: `custom_${userItem.id}`,
      user_item: userItem,
      type,
      quantity
    })

    state.bag[type].slots = [...state.bag[type].slots, ...[
      {
        key: `custom_${userItem.id}`,
        user_item: userItem,
        type,
        quantity
      }
    ]]
  }
})
