import Heading from '@atoms/Heading'
import Snow from 'components/layout/holidays/Snow'

export default function Card ({ className, title, children, snow }) {
  return (
    <div className="relative h-full">
      <div className={`p-5 rounded relative bg-dark lg:overflow-y-auto overflow-x-hidden ${className}`}>
        {title && (
          <Heading
            type="h2"
            className="mb-4"
            bold={false}
          >
            {title}
          </Heading>
        )}

        {children}
      </div>
      {snow && (
        <Snow
          large={true}
          left={true}
          width={200}
          top={-6}
        />
      )}
    </div>
  )
}

Card.defaultProps = {
  className: ''
}
