import Link from 'next/link'
import { useDispatch, useSelector } from 'react-redux'
import { Transition } from 'react-transition-group'
import { setMobileMenu } from '../../store'
import { useRouter } from 'next/dist/client/router'
import { useEffect } from 'react'
import Button from '@atoms/Button'
import ButtonLink from '@atoms/ButtonLink'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes, faUserCircle } from '@fortawesome/pro-solid-svg-icons'
import { faDiscord, faXTwitter } from '@fortawesome/free-brands-svg-icons'
import { showWalletOverlay } from 'redux/raids'
import { faArrowUpRightFromSquare, faBookSparkles, faCrystalBall, faGem, faHatWizard, faScrollOld, faSwords, faTrophy } from '@fortawesome/pro-duotone-svg-icons'
import NavListItem from './NavListItem'

export default function MobileNav () {
  const isOpen = useSelector(state => state.main.mobileMenu.isOpen)
  const dispatch = useDispatch()
  const router = useRouter()

  useEffect(() => {
    router.events.on('routeChangeStart', () => {
      dispatch(setMobileMenu(false))
      dispatch(showWalletOverlay(false))
    })
  }, [])

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden'
      document.body.style.position = 'fixed'
    } else {
      document.body.style.overflow = 'auto'
      document.body.style.position = 'static'
    }
  })

  const defaultStyle = {
    visibility: 'hidden',
    opacity: 0
  }

  const transitionStyles = {
    entering: {
      visibility: 'visible',
      opacity: 1
    },
    entered: {
      visibility: 'visible',
      opacity: 1
    },
    exiting: {
      visibility: 'hidden',
      opacity: 0
    },
    exited: {
      visibility: 'hidden',
      opacity: 0
    }
  }

  const links = [
    {
      label: 'Raids',
      icon: faSwords,
      href: '/tavern'
    },
    {
      label: 'Rarity Table',
      icon: faGem,
      href: '/menu'
    },
    {
      label: 'Adventurer Finder',
      icon: faHatWizard,
      href: '/adventurers'
    },
    {
      label: 'Shrines Finder',
      icon: faCrystalBall,
      href: '/shrines-finder'
    },
    {
      label: 'Hall of Fame',
      icon: faTrophy,
      href: '/leaderboard/tavern-brawls',
      active: router.asPath.startsWith('/leaderboard') || router.asPath === '/account/achievements'
    },
    {
      label: 'Tales of the Tavern',
      icon: faBookSparkles,
      href: '/lore/origins/chapter-1',
      active: router.asPath && router.asPath.startsWith('/lore/')
    },
    {
      label: 'White Scroll',
      icon: faScrollOld,
      href: 'https://whitescroll.tavernsquad.io',
      target: '_blank',
      iconAfter: faArrowUpRightFromSquare
    }
  ]

  return (
    <Transition
      in={isOpen}
      timeout={200}
    >
      {state => (
        <div
          className="fixed inset-0 flex w-full h-full text-xl bg-center bg-cover bg-background-fallback bg-banner transition-all duration-200 z-50 lg:hidden"
          style={{
            ...defaultStyle,
            ...transitionStyles[state]
          }}
        >
          <div className="w-full py-5 rounded bg-tertiary bg-opacity-80 ring-1 ring-primary ring-inset">
            <div className="container relative flex justify-between px-6 mx-auto">
              <Link
                href="/"
                legacyBehavior
              >
                <a className="flex">
                  <img
                    src="/ale-and-axes-full-logo.png"
                    alt="Tavern Squad logo"
                    className={router.pathname === '/' ? 'h-16 lg:h-18' : 'h-12'}
                  />
                </a>
              </Link>

              <Button
                className="flex items-center p-3 align-middle rounded lg:hidden"
                onClick={() => dispatch(setMobileMenu(false))}
              >
                <FontAwesomeIcon
                  icon={faTimes}
                  className="w-8 h-8"
                />
              </Button>
            </div>

            <nav className="pt-8 space-y-6">
              <div className="flex flex-col justify-center px-6">
                <ul className="flex flex-col text-center">
                  {links.map(link => {
                    return (
                      <NavListItem
                        link={link}
                        key={link.href}
                        centered={true}
                      />
                    )
                  })}
                </ul>
              </div>

              <ul className="flex flex-col text-center space-y-3">
                <li>
                  <ButtonLink
                    href="/tavern"
                    className="inline-block px-4 py-2 align-middle rounded bg-tertiary-hover ring-1 ring-primary-hover ring-inset transition-colors"
                  >
                    <FontAwesomeIcon
                      icon={faUserCircle}
                      className="inline w-5 h-5 lg:mr-2"
                    />
                    <span>
                      {' '}
                      Account
                    </span>
                  </ButtonLink>
                </li>
              </ul>
              <div className="flex justify-center items-center">
                <ButtonLink
                  href="https://twitter.com/TavernSquadNFT"
                  target="_blank"
                  rel="noreferrer"
                  className="px-4 p-2 rounded mx-2 bg-twitter hover:bg-twitter-hover transition-colors border border-white"
                >
                  <FontAwesomeIcon
                    icon={faXTwitter}
                    className="block w-5 h-5"
                  />
                </ButtonLink>
                <ButtonLink
                  href={process.env.NEXT_PUBLIC_DISCORD_LINK}
                  target="_blank"
                  rel="noreferrer"
                  className="px-4 p-2 rounded mx-2 bg-discord hover:bg-discord-hover transition-colors border border-white"
                >
                  <FontAwesomeIcon
                    icon={faDiscord}
                    className="block w-5 h-5"
                  />
                </ButtonLink>
              </div>
            </nav>
          </div>
        </div>
      )}
    </Transition>
  )
}
