import axios from 'helpers/api'

export async function craftItem ({ item, batchSize }) {
  return await axios.post('/api/craft-batches', {
    item_id: item.id,
    batch_size: batchSize
  }).then(({ data }) => {
    return data
  })
}

export async function claimCraftBatch ({ craftBatch }) {
  return await axios.post(`/api/craft-batches/${craftBatch.id}/claim`).then(({ data }) => {
    return data
  })
}

/**
 * userItems should be an object with user_item_id as key and quantity to destroy as value
 */
export async function destroyItems ({ userItems, powerCoreMode }) {
  return await axios.post('/api/items/destroy', {
    user_items: userItems,
    power_core_mode: powerCoreMode
  }).then(({ data }) => {
    return data
  })
}

export async function purchaseItems ({ vendorItem, quantity }) {
  return await axios.post('/api/vendors/purchase', {
    vendor_item_id: vendorItem.id,
    quantity
  }).then(({ data }) => {
    return data
  })
}

export async function getEnchantingCost ({ userItemIds }) {
  return await axios.post('/api/items/get-enchanting-cost', {
    user_item_ids: userItemIds
  }).then(({ data }) => {
    return data
  })
}

export async function enchantItems ({ userItemIds, failStackUserItemId = null, failSaveUserItemId = null }) {
  return await axios.post('/api/items/enchant', {
    user_item_ids: userItemIds,
    fail_stack_user_item_id: failStackUserItemId,
    fail_save_item_id: failSaveUserItemId
  }).then(({ data }) => {
    return data
  })
}

export async function getRestoreDurabilityCost ({ userItemIds, useShards }) {
  return await axios.post('/api/items/get-restore-durability-cost', {
    user_item_ids: userItemIds,
    use_shards: useShards ?? false
  }).then(({ data }) => {
    return data
  })
}

export async function restoreDurability ({ userItemIds, raiderId, useShards }) {
  return await axios.post('/api/items/restore-durability', {
    user_item_ids: userItemIds,
    raider_id: raiderId,
    use_shards: useShards ?? false
  }).then(({ data }) => {
    return data
  })
}

export async function convertEssenceFlakes () {
  return await axios.post('/api/items/convert-essence-flakes').then(({ data }) => {
    return data
  })
}

export async function learnProfession ({ profession }) {
  return await axios.post(`/api/professions/${profession.identifier}/learn`).then(({ data }) => {
    return data
  })
}

export async function levelUpProfession ({ userProfession }) {
  return await axios.post(`/api/user-professions/${userProfession.id}/level-up`)
    .then(({ data }) => {
      return data
    })
}
