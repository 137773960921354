import PropTypes from 'prop-types'
import Link from 'next/link'
import { motion } from 'framer-motion'

export default function ButtonLink ({ primary, raids, secondary, outlined, bounce, href, children, className, ...props }) {
  let classes = ''
  const animations = {
    tap: {},
    hover: {}
  }

  if (primary) {
    classes = 'inline-block bg-secondary border rounded border-primary transition-colors hover:bg-secondary-hover disabled:hover:bg-secondary disabled:cursor-not-allowed'

    if (!/\bp(x|y|t|r|b|l)?-\d+\b/.test(className)) {
      classes += ' px-4 py-2'
    }
  }

  if (raids) {
    classes = 'rounded-sm border-b-6 color-white px-4 py-2 border-light-border'

    if (!className.includes('bg-')) {
      classes += ' bg-raids-primary hover:bg-raids-primary-accent'
    }
    if (!className.includes('font-')) {
      classes += ' font-display uppercase'
    }
  }

  if (secondary) {
    classes = 'rounded-sm border-b-6 color-white px-4 py-2 border-raids-darker bg-darker-gray'
  }

  if (outlined) {
    classes = 'bg-transparent rounded border border-primary color-white px-4 py-3 hover:bg-primary'
  }

  classes += ' whitespace-nowrap'

  if ((primary || bounce) && !props.disabled) {
    animations.hover.scale = 1.05
    animations.tap.scale = 0.95
  }

  return (
    <Link
      href={href}
      passHref={true}
      legacyBehavior
    >
      <motion.a
        whileHover={animations.hover}
        whileTap={animations.tap}
        className={`${classes} ${className}`}
        {...props}
      >
        <span className={props.disabled ? 'opacity-70' : ''}>
          {children}
        </span>
      </motion.a>
    </Link>
  )
}

ButtonLink.propTypes = {
  primary: PropTypes.bool,
  bounce: PropTypes.bool,
  href: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.any
}

ButtonLink.defaultProps = {
  primary: false,
  bounce: false,
  className: ''
}
