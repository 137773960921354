export function getItemTypeLabel (item) {
  if (item.type === 'consumable') {
    return 'Consumable'
  }
  if (item.type === 'fail_stack') {
    return 'Fail stack'
  }
  if (item.type === 'resource') {
    return 'Resource'
  }
  if (item.type === 'recipe') {
    return 'Recipe'
  }

  return 'Gear'
}

export function getEnchantingLevelLabel (category, level) {
  if (category === 'A') {
    if (level === 11) {
      return 'PRI'
    } else if (level === 12) {
      return 'DUO'
    } else if (level === 13) {
      return 'TRI'
    } else if (level === 14) {
      return 'TET'
    } else if (level === 15) {
      return 'PEN'
    }
  }

  if (category === 'B') {
    if (level === 1) {
      return 'PRI'
    } else if (level === 2) {
      return 'DUO'
    } else if (level === 3) {
      return 'TRI'
    } else if (level === 4) {
      return 'TET'
    } else if (level === 5) {
      return 'PEN'
    }
  }

  return level
}
