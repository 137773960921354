export default function Level (props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="w-6 h-6"
      fill="none"
      viewBox="0 0 50 43"
      stroke="none"
      {...props}
    >
      <g fill="#1f2937">
        <path d="M37.212 42.5H12.788l-12.21-21 12.21-21h24.424l12.21 21-12.21 21z"></path>
        <path
          fill="currentColor"
          d="M13.075 1L1.157 21.5 13.075 42h23.85l11.918-20.5L36.925 1h-23.85M12.5 0h25L50 21.5 37.5 43h-25L0 21.5 12.5 0z"
        ></path>
      </g>
    </svg>
  )
}
