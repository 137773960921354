const networkId = parseInt(process.env.NEXT_PUBLIC_ADA_NETWORK)

let network = 1
let craftAddress = 'addr1qygpdr7mcpcakavagrygygc2egwdgl0awgr78r2dnretqyay0z634556j025k6xtk3ank4r7x39naj2yjmug02v0h5aq38d625'
let vendorAddress = 'addr1qxemf3wh534qzcnq8jxuecx3sukk3w56ets8zenr0nzatm6n2aslv0yq82lz5fwtq2qyeuw0xtkgt8splpm7pt9xrhzqne6tcy'
let shopAddress = 'addr1q9yj0qqqez8ldpdmxeaa3pwv4tyrws9upkdc8c7dwd9dmzqzc5zh4c80psfjrjqr8eucj7aw8mseprdzf8dldsnn2k2sh7uknp'
let advPolicy = '2d01b3496fd22b1a61e6227c27250225b1186e5ebae7360b1fc5392c'
let coinPolicy = '4ffaa4ef3217df37c4995bb96066af4cb68dfcc66b9f2a10e0c333b9'
let coinAsset = '5779726d73746f6e65'
let shrineUpgradePaymentToken = '4ffaa4ef3217df37c4995bb96066af4cb68dfcc66b9f2a10e0c333b95779726d73746f6e65'

if (networkId === 0) {
  network = 0
  // Eternl
  craftAddress = 'addr_test1qrd563qactwwwgzvg9lv9e5etgth5ryrjjp3yv6ekd5ky2xke7f7df88z4rldr8v6x03k3wxaj65j6kmth6es36dmf9q0rhe9h'
  // Nami uckie.thera
  vendorAddress = 'addr_test1qph3qzp7n02qlvq2ngk8z7vphf0x6tyhu54dan4tdh33kj0mxgkagel045h2u04ajtnusydeweudltfzcpx8s225h46sk7znl7'
  shopAddress = 'addr1q9yj0qqqez8ldpdmxeaa3pwv4tyrws9upkdc8c7dwd9dmzqzc5zh4c80psfjrjqr8eucj7aw8mseprdzf8dldsnn2k2sh7uknp'
  advPolicy = 'b05fcc8a6e7e2a6e58e932fe8b70a169d975f7b7d99ac78b6c6af135'
  coinPolicy = 'a07d752e48c8a8dcadf465bdf2c1f1b2b58740ce8ea3aaca6d1658cf'
  coinAsset = '5759524d53544f4e45'
  shrineUpgradePaymentToken = '04eaeec92270325ffae7d0277ed7e2aab3717eea8a9698f9a77ad3e00014df10576f6e64657266756c426c6f6231'
}

export const ADA_IN_LOVELACE = 1000000 // ADA has 6 decimals
export const ADA_IN_LOVELACE_BIG = 1000000n // ADA has 6 decimals
export const SINGLE_WYRMSTONE = 1000000 // Wyrmstone has 6 decimals
export const SINGLE_WYRMSTONE_BIG = 1000000n // Wyrmstone has 6 decimals
export const CRAFT_FEE = ADA_IN_LOVELACE_BIG * 5n
export const UNLOCK_RAID_UPGRADE_FEE = ADA_IN_LOVELACE_BIG * 10n
export const NETWORK = network
export const CRAFT_ADDRESS = craftAddress
export const VENDOR_ADDRESS = vendorAddress
export const SHOP_ADDRESS = shopAddress
export const ADV_POLICY = advPolicy
export const COIN_POLICY = coinPolicy
export const COIN_ASSET = coinAsset
export const SHRINE_UPGRADE_PAYMENT_TOKEN = shrineUpgradePaymentToken
