import PropTypes from 'prop-types'
import { motion } from 'framer-motion'

const Button = ({ primary, raids, secondary, failure, outlined, bounce, children, className, ...props }, ref) => {
  let classes = ''
  const animations = {
    tap: {},
    hover: {}
  }

  if (primary) {
    classes = 'bg-secondary border rounded-sm border-primary transition-colors hover:bg-secondary-hover'

    if (!/\bp(x|y|t|r|b|l)?-\d+\b/.test(className)) {
      classes += ' px-4 py-2'
    }
  }

  if (raids) {
    classes = 'rounded-sm border-b-6 color-white px-4 py-2 border-light-border'

    if (!className.includes('bg-')) {
      classes += ' bg-raids-primary hover:bg-raids-primary-accent'
    }
    if (!className.includes('font-')) {
      classes += ' font-display uppercase'
    }
  }

  if (secondary) {
    classes = 'bg-tertiary rounded-sm color-white hover:bg-tertiary-hover p-2'
  }

  if (outlined) {
    classes = 'bg-transparent rounded-sm border border-primary color-white p-2 hover:bg-primary'
  }

  classes += ' disabled:border-opacity-20 disabled:bg-opacity-20 disabled:cursor-not-allowed'

  if ((primary || bounce) && !props.disabled) {
    animations.hover.scale = 1.05
    animations.tap.scale = 0.95
  }

  return (
    <motion.button
      whileHover={animations.hover}
      whileTap={animations.tap}
      ref={ref}
      className={`${classes} ${className}`}
      {...props}
    >
      <span className={props.disabled ? 'opacity-70' : ''}>
        {children}
      </span>
    </motion.button>
  )
}

Button.propTypes = {
  primary: PropTypes.bool,
  bounce: PropTypes.bool,
  children: PropTypes.node.isRequired,
  className: PropTypes.any,
  disabled: PropTypes.bool
}

Button.defaultProps = {
  primary: false,
  bounce: false,
  disabled: false,
  className: ''
}

export default Button
