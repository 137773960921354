import useSWR from 'swr'
import useSWRInfinite from 'swr/infinite'
import axios from 'helpers/api'

const fetcher = uri => axios.get(uri).then(res => res.data)
const fetcherInfinite = (key, uri) => axios.get(uri).then(res => res.data[key])

const getOptions = (options, immutable = false, setLastUpdated = null) => {
  if (immutable) {
    options = {
      ...options,
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false
    }
  }

  if (setLastUpdated) {
    options = {
      onSuccess: () => setLastUpdated(Date.now()),
      ...options
    }
  }

  return {
    focusThrottleInterval: 1000 * 30,
    ...options
  }
}

export default function useApi (uri, options = {}, immutable = false, setLastUpdated = null) {
  const { data, error, isValidating, mutate } = useSWR(uri, fetcher, getOptions(options, immutable, setLastUpdated))

  return {
    data,
    error,
    isLoading: !error && !data,
    isValidating,
    mutate
  }
}

export function useApiInfinite (uri, options = {}, setLastUpdated = null) {
  const { data, error, isValidating, size, setSize } = useSWRInfinite(uri, fetcherInfinite.bind(null, 'raids'), getOptions(options, false, setLastUpdated))

  return {
    data,
    error,
    isLoading: !error && !data,
    isValidating,
    size,
    setSize
  }
}
