import axios from 'helpers/api'

export async function markNotificationAsRead (notificationId) {
  const response = await axios.put(`/api/notifications/${notificationId}/mark-as-read`).then(({ data }) => {
    return data
  })

  return response
}

export async function markAllNotificationsAsRead () {
  const response = await axios.post('/api/notifications/mark-all-as-read').then(({ data }) => {
    return data
  })

  return response
}
