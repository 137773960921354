import { faPause, faPlay } from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import useOutsideClick from 'hooks/useOutsideClick'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Tooltip } from 'react-tippy'

const trackList = [
  {
    name: 'After The Dungeon',
    source: 'https://d1xrg19s0mtqrh.cloudfront.net/soundtracks/tavern-squad-after-the-dungeon.mp3'
  },
  {
    name: 'A New Day',
    source: 'https://d1xrg19s0mtqrh.cloudfront.net/soundtracks/tavern-squad-a-new-day.mp3'
  },
  {
    name: 'Tales of the Tavern',
    source: 'https://d1xrg19s0mtqrh.cloudfront.net/soundtracks/tavern-squad-tales-of-the-tavern.mp3'
  },
  {
    name: 'After The Dungeon',
    source: 'https://d1xrg19s0mtqrh.cloudfront.net/soundtracks/tavern-squad-after-the-dungeon.mp3'
  },
  {
    name: 'A New Day',
    source: 'https://d1xrg19s0mtqrh.cloudfront.net/soundtracks/tavern-squad-a-new-day.mp3'
  },
  {
    name: 'The Dungeon',
    source: 'https://d1xrg19s0mtqrh.cloudfront.net/soundtracks/tavern-squad-the-dungeon.mp3'
  },
  {
    name: 'After The Dungeon',
    source: 'https://d1xrg19s0mtqrh.cloudfront.net/soundtracks/tavern-squad-after-the-dungeon.mp3'
  },
  {
    name: 'A New Day',
    source: 'https://d1xrg19s0mtqrh.cloudfront.net/soundtracks/tavern-squad-a-new-day.mp3'
  }
]

const AudioPlayer = ({ route }) => {
  const user = useSelector(store => store.main.auth.user)
  const [audio] = useState(new Audio())
  const [playing, setPlaying] = useState(false)
  const [userInteracted, setUserInteracted] = useState(false)
  const [hasPaused, setHasPaused] = useState(false)
  const [currentTrack, setCurrentTrack] = useState(0)
  const handleOutsideClick = () => {
    if (userInteracted) {
      return
    }
    setUserInteracted(true)
  }
  const ref = useOutsideClick(handleOutsideClick)

  const loadTrack = trackToLoad => {
    audio.src = trackToLoad
    audio.load()
  }

  useEffect(() => {
    const handleAudioEnd = async () => {
      audio.pause()
      setTimeout(async () => {
        const newTrack = currentTrack === (trackList.length - 1) ? 0 : currentTrack + 1
        setCurrentTrack(newTrack)
        loadTrack(trackList[newTrack].source)
        audio.currentTime = 0
        setTimeout(async () => {
          await audio.play()
        }, 3000)
      }, 500)
    }

    audio.addEventListener('ended', handleAudioEnd)

    return () => {
      audio.removeEventListener('ended')
    }
  }, [currentTrack])

  useEffect(() => {
    if (audio && window.innerWidth > 768 && userInteracted && !playing && !user.disable_autoplay && !hasPaused) {
      loadTrack(trackList[currentTrack].source)
      audio?.play()
      setPlaying(true)
    }
  }, [userInteracted])

  const togglePlay = async () => {
    if (playing) {
      audio.pause()
      setHasPaused(true)
    } else {
      loadTrack(trackList[currentTrack].source)
      await audio.play()
    }
    setPlaying(!playing)
  }

  return (
    <div ref={ref}>
      <Tooltip title={`${playing ? `<strong>Now playing: ${trackList[currentTrack].name}</strong><br/>` : ''} Play/pause soundtrack. You can enable/disable autoplay in settings.`}>
        <button
          type="button"
          onClick={togglePlay}
          className="w-[60px] h-[60px] p-5 rounded hover:bg-raids-dark hover:bg-opacity-40 flex items-center justify-center"
        >
          <FontAwesomeIcon
            icon={playing ? faPause : faPlay}
            className="w-6 h-6"
          />
        </button>
      </Tooltip>
    </div>
  )
}

export default AudioPlayer
