import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setBag, setBagOpen } from 'redux/professions'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBackpack } from '@fortawesome/pro-duotone-svg-icons'
import { updateBags } from 'helpers/bags'

export default function BagToggle () {
  const dispatch = useDispatch()
  const bagOpen = useSelector(store => store.professions.bagOpen)

  const handleOpen = () => {
    dispatch(setBagOpen(!bagOpen))
  }

  const fetchBags = async () => {
    updateBags(dispatch, setBag)
  }

  useEffect(() => {
    fetchBags()
  }, [])

  return (
    <div
      className="absolute left-3 bottom-3"
    >
      <button
        type="button"
        onClick={handleOpen}
        className="bg-dark rounded p-3 z-30 absolute left-6 bottom-4"
      >
        <FontAwesomeIcon
          icon={faBackpack}
          className="w-6 h-6 text-white hover:text-primary"
        />
      </button>
    </div>
  )
}
