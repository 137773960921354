import { faCircleNotch } from '@fortawesome/pro-duotone-svg-icons'
import { faArrowRight, faBell, faCircle } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import useApi from 'hooks/useApi'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import HumanReadableAge from 'components/HumanReadableAge'
import Link from 'next/link'
import { markAllNotificationsAsRead } from 'helpers/notifications'
import { handleError } from 'helpers/raidsTx'
import useOutsideClick from 'hooks/useOutsideClick'

export default function Notifications () {
  const user = useSelector(store => store.main.auth.user)
  const [open, setOpen] = useState(false)
  const { data, isLoading, mutate } = useApi(user ? '/api/notifications/me' : null, { refreshInterval: 1000 * 60 * 1 }, false)
  const unread = data ? data.unread_count : 0
  const notifications = data && data.notifications ? data.notifications.data : null
  const dispatch = useDispatch()
  const handleOutsideClick = () => {
    setOpen(false)
  }
  const ref = useOutsideClick(handleOutsideClick)

  const handleOpen = async () => {
    if (!open) {
      try {
        markAllNotificationsAsRead()
      } catch (e) {
        handleError(e, dispatch)
      }
    } else {
      mutate()
    }

    setOpen(!open)
  }

  return (
    <div
      className="relative"
      ref={ref}
    >
      <button
        type="button"
        className={`relative flex items-center justify-center w-5 h-5 lg:w-[60px] lg:h-[60px] lg:p-5 rounded ${open ? 'bg-raids-darkest' : 'hover:bg-raids-dark hover:bg-opacity-40'}`}
        onClick={handleOpen}
      >
        {isLoading && (
          <FontAwesomeIcon
            icon={faCircleNotch}
            className="absolute w-3 h-3 top-0 right-0 lg:top-4 lg:right-4 animate-spin"
          />
        )}

        {unread > 0 && !open && (
          <>
            <FontAwesomeIcon
              icon={faCircle}
              className="absolute w-2 h-2 top-0 right-0 lg:top-4 lg:right-4 text-failure animate-ping"
            />
            <FontAwesomeIcon
              icon={faCircle}
              className="absolute w-2 h-2 top-0 right-0 lg:top-4 lg:right-4 text-failure"
            />
          </>

        )}

        <FontAwesomeIcon
          icon={faBell}
          className="w-4 h-4 lg:w-6 lg:h-6"
        />
      </button>

      {notifications && (
      <div
        className={`flex fixed top-[56px] left-0 right-0 bottom-0 z-30 justify-center rounded rounded-t-none border border-primary bg-dark lg:absolute lg:top-[78px] lg:left-auto lg:right-0 lg:w-[500px] lg:bottom-auto lg:rounded-t ${!open ? 'hidden' : ''}`}
      >
        <div className="relative p-3 w-full lg:h-96 overflow-y-auto">
          {notifications.length > 0 && notifications.map(notification => {
            return (
              <div
                key={notification.id}
                className="rounded relative pl-2 mb-2"
              >
                {notification.display_data.interaction_type === 'link' && (
                  <Link
                    href={notification.display_data.link}
                    className="absolute top-0 right-0 bottom-0 left-0 z-10"
                    target={notification.display_data.link_target}
                  ></Link>
                )}
                <div className={`absolute top-0 bottom-0 left-0 w-2 h-full rounded-l grow-0 ${notification.read_at ? 'bg-read' : 'bg-primary'}`}></div>
                <div className={`p-3 pr-10 bg-tertiary grow text-md rounded-r ${notification.read_at ? 'opacity-70' : ''}`}>
                  {notification.display_data.message}
                  {notification.display_data.link_text && (
                    <>
                      {' '}
                      <span className="underline">
                        {notification.display_data.link_text}
                      </span>
                    </>
                  )}
                  <div className="text-xs opacity-50">
                    <HumanReadableAge
                      startText=" "
                      date={new Date(notification.created_at)}
                    />
                  </div>
                </div>
                <div className={'absolute top-1/2 -translate-y-1/2 right-6'}>
                  <FontAwesomeIcon
                    icon={faArrowRight}
                    className="w-4 h-4"
                  />
                </div>
              </div>
            )
          })}

          {notifications.length === 0 && (
            <div className="p-3 rounded bg-tertiary">
              You currently don&apos;t have any notifcations. We will ring the bell when you do!
            </div>
          )}
        </div>
      </div>
      )}
    </div>
  )
}
