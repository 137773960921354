import PropTypes from 'prop-types'

export default function Heading ({ type, size, className, children, bold }) {
  const El = type
  size = size || type

  const textSize = () => {
    if (size === 'h1') {
      return 'text-2xl'
    }

    if (size === 'h2') {
      return 'text-xl'
    }

    if (size === 'h3') {
      return 'text-lg'
    }

    return size || null
  }

  return (
    <El className={`${bold ? 'font-bold' : ''} ${textSize()} ${className}`}>
      {children}
    </El>
  )
}

Heading.propTypes = {
  children: PropTypes.node.isRequired,
  type: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4']),
  size: PropTypes.string,
  className: PropTypes.any,
  bold: PropTypes.bool
}

Heading.defaultProps = {
  type: 'h1',
  className: '',
  bold: true
}
