const KNOWN_WALLETS = {
  vespr: 'VESPR',
  eternl: 'Eternl',
  nami: 'Nami',
  flint: 'Flint',
  gerowallet: 'Gero',
  typhoncip30: 'Typhon'
}

function waitForCardano () {
  return new Promise((resolve, reject) => {
    if (typeof window.cardano === 'object') {
      resolve()
    }

    let loopCount = 0

    const interval = setInterval(() => {
      if (typeof window.cardano === 'object') {
        clearInterval(interval)
        resolve()

        return
      }

      ++loopCount

      if (loopCount > 8) {
        clearInterval(interval)
        resolve()
      }
    }, 250)
  })
}

export async function checkSupportedWallets (includeOther = true) {
  try {
    await waitForCardano()
  } catch (e) {
    return []
  }

  if (!window.cardano) {
    if (includeOther) {
      return [
        {
          id: 'other',
          label: 'Other/HW'
        }
      ]
    }

    return []
  }

  const supportedWallets = Object.keys(KNOWN_WALLETS)
  const availableWallets = []

  // TEMP: Only show VESPR wallet if vespr is found in the browser
  if (typeof window.cardano.vespr === 'object' && typeof window.cardano.vespr.enable === 'function') {
    availableWallets.push({
      id: 'vespr',
      label: 'VESPR'
    })
    return availableWallets
  }

  for (const key of supportedWallets) {
    if (typeof window.cardano[key] === 'object' && typeof window.cardano[key].enable === 'function') {
      availableWallets.push({
        id: key,
        label: KNOWN_WALLETS[key]
      })
    }
  }

  if (includeOther) {
    availableWallets.push({
      id: 'other',
      label: 'Other/HW'
    })
  }

  return availableWallets
}
