import { faDownLong } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { formatNumber } from 'accounting'
import { Tooltip } from 'react-tippy'

export default function Stat ({ stat, hasLoweredStats }) {
  return (
    <div>
      +
      {formatNumber(stat.value, { precision: 1 }).replace(/\.0$/, '')}
      {stat.stat.type === 'secondary' ? '%' : ''}
      {' '}
      {stat.stat.name}
      {hasLoweredStats && (
        <Tooltip title="This item has lower stats because of low durability. Repair this item to regain full stats.">
          <FontAwesomeIcon
            icon={faDownLong}
            className="w-3 h-3 align-middle text-failure ml-1"
          />
        </Tooltip>
      )}
    </div>
  )
}
