import { Blockfrost, Lucid } from 'lucid-cardano'
import store from '../store'

export const getLucid = async () => {
  const state = store.getState()
  const selectedWallet = state.raids.selectedWallet
  const networkId = parseInt(process.env.NEXT_PUBLIC_ADA_NETWORK)
  const network = networkId === 0 ? 'Preview' : 'Mainnet'
  const apiUrl = networkId === 0 ? 'https://cardano-preview.blockfrost.io/api/v0' : 'https://cardano-mainnet.blockfrost.io/api/v0'
  const lucid = await Lucid.new(
    new Blockfrost(apiUrl, networkId === 0 ? process.env.NEXT_PUBLIC_TESTNET_BLOCKFROST_PROJECT_ID : process.env.NEXT_PUBLIC_BLOCKFROST_PROJECT_ID),
    network
  )

  if (selectedWallet) {
    const api = await window.cardano[selectedWallet].enable()
    lucid.selectWallet(api)
  }

  return lucid
}
