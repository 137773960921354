import { useEffect, useState } from 'react'
import { formatDistanceToNow } from 'date-fns'

export default function HumanReadableAge ({ date, startText, ...props }) {
  const [text, setText] = useState('')
  startText = startText || 'updated'

  useEffect(() => {
    const calculate = () => {
      setText(formatDistanceToNow(date, { addSuffix: true }))
    }

    calculate()
    const interval = setInterval(calculate, 5000)

    return () => clearInterval(interval)
  }, [date])

  return (
    <span {...props}>
      {startText}
      {' '}
      {text}
    </span>
  )
}
