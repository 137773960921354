import Link from 'next/link'
import { useSelector } from 'react-redux'
import { useRouter } from 'next/router'
import ButtonLink from '@atoms/ButtonLink'

export default function TopNav () {
  const router = useRouter()
  const user = useSelector(store => store.main.auth.user)
  const pathname = router.pathname

  const tabs = [
    {
      name: 'Whitepaper',
      active: false,
      link: 'https://whitescroll.tavernsquad.io/',
      newTab: true
    },
    {
      name: 'Adventurer Finder',
      active: pathname.includes('adventurers'),
      link: '/adventurers',
      newTab: false
    },
    {
      name: 'Shrines Finder',
      active: pathname.includes('shrines-finder'),
      link: '/shrines-finder',
      newTab: false
    },
    {
      name: 'Rarity Table',
      active: pathname.includes('menu'),
      link: '/menu',
      newTab: false
    }
  ]

  return (
    <div
      className="w-full py-2 h-[60px] z-[50] fixed top-0 hidden bg-dark bg-opacity-40 md:flex"
      style={{ backdropFilter: 'blur(4px)' }}
    >
      <div className="absolute w-full h-full z-[50] top-0 opacity-[0.5] bg-gray-primary"></div>

      <div className="w-full flex mx-6 sm:mx-12 justify-between z-[60]">
        <Link
          href={'/'}
          className="flex justify-center items-center"
        >
          <img
            src={'/ale-and-axes-logo.png'}
            alt="Ale & Axes"
            className="h-8"
          />
        </Link>

        <div className="flex items-center justify-center">
          {tabs.map((tab, index) => {
            return (
              <a
                href={tab.link}
                key={'top-nav-' + tab.name + index}
                target={tab.newTab ? '_blank' : '_self'}
              >
                <div
                  className={`flex text-sm font-bold uppercase px-6 hover:text-orange-primary duration-150 ${tab.active ? 'text-orange-primary' : 'text-white'}`}
                >
                  {tab.name}
                </div>
              </a>
            )
          })}
        </div>

        {user && (
          <ButtonLink
            href="/tavern"
            className="w-auto font-sans"
            raids
          >
            To the tavern
          </ButtonLink>
        )}

        {!user && (
          <ButtonLink
            href="/login"
            className="w-auto font-sans"
            raids
          >
            Login
          </ButtonLink>
        )}

      </div>
    </div>
  )
}
