import { useEffect, useState } from 'react'

export default function Barty () {
  const [messsage, setMessage] = useState('')
  const [showMessage, setShowMessage] = useState(false)

  const saySomething = () => {
    if (showMessage) {
      return
    }

    const messages = [
      'Hey Brawler!',
      'Raid time!',
      'Uckie! WEN Professions!??',
      'I wonder when Brunthar and his gang will return...',
      'Welcome to the Tavern!',
      'I remember those days... go raiding, get treasure...',
      'These Tavarn Brawls have been out of control lately..',
      'Don\'t forget to activate your shrines!',
      'Did you know you can craft a buff to boost raid performance?',
      'Tip: Make sure to get close to that 100% success chance!',
      'Tip: Raid in the deepest depth to get most Brawl Points',
      'Just imagine failing a 99% raid...',
      'Do not forget about the Grand Chest!',
      'Help out Tavern Squad by posting your favorite Adventurer on X',
      'The secret to Tavern Brawls? No sleep!',
      'What can I get you? Some ale?',
      'Tip: Higher Tier Adventurers have higher ROI',
      'Want to raid on mobile? Go to aleaxes.com in your mobile browser!',
      'You can upgrade your Shrines in your inventory',
      'Do not forget to upgrade your Shrines!',
      'You can play on mobile using any mobile browser'
    ]

    const randomIndex = Math.floor(Math.random() * messages.length)
    setMessage(messages[randomIndex])
    setShowMessage(true)
    setTimeout(() => {
      setShowMessage(false)
    }, 5000)
  }

  useEffect(() => {
    setTimeout(() => {
      saySomething()
    }, 5000)

    const doSomething = () => {
      saySomething()
    }

    const interval = setInterval(doSomething, 60000)

    return () => clearInterval(interval)
  }, [])

  return (
    <>
      <div className="fixed -bottom-[14px] -right-[14px] z-30 hidden lg:block">
        <button
          type="button"
          onClick={saySomething}
        >
          <img
            src={'/other/barty.jpg'}
            alt="Barty"
            className={'rounded-full w-24 h-24 border-primary border'}
          />
        </button>
      </div>
      <div className={`fixed bottom-[40px] right-[90px] max-w-[180px] text-center rounded bg-white p-2 px-3 font-semibold text-black text-sm hidden ${showMessage ? 'lg:block' : ''}`}>
        {messsage}
      </div>
    </>
  )
}
