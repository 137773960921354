import { configureStore, createAction, createReducer } from '@reduxjs/toolkit'
import raidsReducer from './redux/raids'
import mintReducer from './redux/mint'
import professionsReducer from './redux/professions'

const initialState = {
  csrfReady: false,
  auth: {
    user: null,
    userUpdatedAt: null,
    justLoggedOut: false
  },
  menu: {
    isOpen: true
  },
  toolsMenu: {
    isOpen: false
  },
  mobileMenu: {
    isOpen: false
  },
  tabs: {
    open: null
  }
}

export const setMenu = createAction('main/menu')
export const setToolsMenu = createAction('main/toolsMenu')
export const setMobileMenu = createAction('main/mobileMenu')
export const csrfReady = createAction('main/csrfReady')
export const setAuthUser = createAction('main/authUser')
export const logOut = createAction('main/logOut')
export const setOpenTab = createAction('main/setOpenTab')

const mainReducer = createReducer(initialState, {
  [setMenu.type]: (state, { payload }) => ({
    ...state,
    menu: {
      isOpen: payload
    }
  }),
  [setToolsMenu.type]: (state, { payload }) => ({
    ...state,
    toolsMenu: {
      isOpen: payload
    }
  }),
  [setMobileMenu.type]: (state, { payload }) => ({
    ...state,
    mobileMenu: {
      isOpen: payload
    }
  }),
  [csrfReady.type]: state => ({
    ...state,
    csrfReady: true
  }),
  [setAuthUser.type]: (state, { payload }) => ({
    ...state,
    auth: {
      ...state.auth,
      user: payload,
      userUpdatedAt: Date.now()
    }
  }),
  [logOut.type]: state => ({
    ...state,
    auth: {
      ...state.auth,
      user: null,
      justLoggedOut: true
    }
  }),
  [setOpenTab.type]: (state, { payload }) => ({
    ...state,
    tabs: {
      open: payload
    }
  })
})

const store = configureStore({
  reducer: {
    main: mainReducer,
    raids: raidsReducer,
    mint: mintReducer,
    professions: professionsReducer
  }
})

export default store
