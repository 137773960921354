import { faArrowUpRight } from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export default function WalletOverlay () {
  return (
    <div className="fixed lg:absolute z-20 bg-black top-14 right-0 bottom-0 left-0 space-y-6 bg-opacity-60 lg:top-32">
      <div className="flex absolute flex-col text-2xl top-0 right-40 sm:right-60 lg:right-48">
        <div className="w-full flex justify-end mb-2">
          <FontAwesomeIcon
            icon={faArrowUpRight}
            className="w-12 h-12 animate-wallet-point"
          />
        </div>
        <div>
          Please connect a
          <br />
          wallet to continue
        </div>
        <div className="lg:hidden text-xs mt-2">
          At this moment only the VESPR
          <br/>
          wallet is supported on mobile.
          <br/>
          Open the Tavern Squad website
          {' '}
          <br/>
          inside the VESPR app!
        </div>
      </div>
    </div>
  )
}
