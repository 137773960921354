import { faClose } from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { twMerge } from 'tailwind-merge'

export default function Box ({ children, header, actions, className, childrenClassName, onClose }) {
  return (
    <>
      <div className={twMerge('relative bg-dark rounded h-full flex flex-col', className)}>
        {header && (
          <div className="grow-0 bg-raids-dark p-3 rounded-t flex w-full">
            {header}
          </div>
        )}
        <div className={twMerge('grow max-h-[480px] overflow-y-auto', childrenClassName)}>
          {children}
        </div>
        {actions && (
          <div className="grow-0 mt-auto bg-raids-dark p-3 rounded-b flex w-full items-center">
            {actions}
          </div>
        )}
        {onClose && (
          <button
            type="button"
            onClick={onClose}
            className="absolute top-1.5 right-1.5"
          >
            <FontAwesomeIcon
              icon={faClose}
              className="text-white w-6 h-6"
            />
          </button>
        )}
      </div>
    </>
  )
}
