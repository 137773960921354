import { useEffect, useState } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { Tooltip } from 'react-tippy'
import Button from '@atoms/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCopy } from '@fortawesome/pro-solid-svg-icons'

export default function Copy ({ text, className }) {
  const [justCopied, setJustCopied] = useState(false)
  const [copiedTimeout, setCopiedTimeout] = useState(null)

  const handleCopied = () => {
    setJustCopied(true)

    if (copiedTimeout) {
      clearTimeout(copiedTimeout)
    }

    setCopiedTimeout(setTimeout(() => {
      setJustCopied(false)
    }, 2000))
  }

  useEffect(() => {
    return () => {
      if (copiedTimeout) {
        clearTimeout(copiedTimeout)
      }
    }
  }, [])

  return (
    <Tooltip
      title="Copied!"
      position="top"
      open={justCopied}
      trigger="manual"
      style={{ display: 'flex' }}
    >
      <CopyToClipboard text={text}>
        <Button
          className={className || 'px-2 border border-l-0 rounded-r bg-secondary border-primary'}
          data-tip={justCopied ? 'Copied' : ''}
          onClick={handleCopied}
        >
          <FontAwesomeIcon
            icon={faCopy}
            className="w-4 h-4"
          />
        </Button>
      </CopyToClipboard>
    </Tooltip>
  )
}
