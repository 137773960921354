import { useRouter } from 'next/router'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'

export default function useAuth (callback, deps = [], disableRedirect) {
  const user = useSelector(store => store.main.auth.user)
  const router = useRouter()

  useEffect(() => {
    if (user === false) {
      window.localStorage.setItem('redirectToUrl', window.location.pathname)

      if (!disableRedirect) {
        router.push('/login')
      }
    }

    if (user && callback) {
      callback(user)
    }
  }, [user, ...deps])

  return user || null
}
