export default function Stat ({ children, value, currency, image }) {
  return (
    <div className={'relative flex items-center rounded-sm p-2 pr-6 leading-4 text-sm bg-tertiary lg:bg-transparent lg:flex-row-reverse lg:pr-4 lg:px-3 lg:p-0'}>
      <span className="flex items-center lg:block lg:text-lg lg:leading-4">
        <span className="lg:block">
          {value}
        </span>
        <span className="hidden text-xs text-raids-text uppercase lg:block lg:normal-case lg:text-sm">
          {currency}
        </span>
        {children}
      </span>
      <img
        src={image}
        alt={currency}
        height={60}
        width={60}
        className="absolute right-0 translate-x-1/2 top-1/2 -translate-y-1/2 w-12 h-12 lg:relative lg:translate-x-0 lg:translate-y-0 lg:top-0 lg:right-0 lg:-ml-2 lg:mr-2 lg:w-10 lg:h-10"
      />
    </div>
  )
}
