import CardanoStacked from '../../icons/CardanoStacked'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDiscord, faXTwitter } from '@fortawesome/free-brands-svg-icons'
import Heading from '@atoms/Heading'
import Copy from 'components/Copy'
import { ADV_POLICY } from 'consts'
import ButtonLink from '@atoms/ButtonLink'

export default function Footer () {
  return (
    <footer className="container px-6 mx-auto pb-6">
      <section className="container flex justify-center p-6 mx-auto my-6 text-center bg-dark rounded">
        <div className="lg:w-1/2 space-y-6">
          <Heading
            type="h3"
            size="h2"
          >
            Policy IDs
          </Heading>

          <p>
            Make sure to check the policy ID when trading or buying
          </p>

          <Heading
            type="h4"
          >
            Tavern Squad NFT Policy ID
          </Heading>

          <div className="flex text-left break-all border rounded bg-secondary border-primary justify-items-stretch">
            <span className="flex-grow p-2">
              {ADV_POLICY}
            </span>

            <Copy
              text={ADV_POLICY}
              className="flex items-center px-4 py-2 border-l border-primary transition-colors hover:bg-secondary-hover"
            />
          </div>

          <Heading
            type="h4"
          >
            Wyrmstone token Policy ID
          </Heading>

          <div className="flex text-left break-all border rounded bg-secondary border-primary justify-items-stretch">
            <span className="flex-grow p-2">
              4ffaa4ef3217df37c4995bb96066af4cb68dfcc66b9f2a10e0c333b9
            </span>

            <Copy
              text="4ffaa4ef3217df37c4995bb96066af4cb68dfcc66b9f2a10e0c333b9"
              className="flex items-center px-4 py-2 border-l border-primary transition-colors hover:bg-secondary-hover"
            />
          </div>
        </div>
      </section>

      <div className="flex items-center justify-between p-6 text-sm bg-dark rounded md:text-base bg-opacity-80 md:space-x-6 lg:space-x-24">
        <div className="flex items-center">
          <span className="mr-4">
            Powered by
            <span className="hidden">
              {' '}
              Cardano
            </span>
          </span>
          <CardanoStacked className="w-8 h-8 xs:w-10 xs:h-10" />
        </div>

        <div className="flex space-x-2 md:space-x-4">
          <ButtonLink
            href="https://twitter.com/TavernSquadNFT"
            target="_blank"
            rel="noreferrer"
            className="p-2 rounded lg:block lg:px-4 bg-twitter hover:bg-twitter-hover transition-colors border border-white"
            bounce
          >
            <FontAwesomeIcon
              icon={faXTwitter}
              className="block w-5 h-5"
            />
          </ButtonLink>
          <ButtonLink
            href={process.env.NEXT_PUBLIC_DISCORD_LINK}
            target="_blank"
            rel="noreferrer"
            className="p-2 rounded lg:block lg:px-4 bg-discord hover:bg-discord-hover transition-colors border border-white"
            bounce
          >
            <FontAwesomeIcon
              icon={faDiscord}
              className="block w-5 h-5"
            />
          </ButtonLink>
        </div>
      </div>
    </footer>
  )
}
