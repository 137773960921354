import axios from 'helpers/api'

export async function updateBags (dispatch, setBag) {
  return await axios.get('/api/bags/me').then(({ data }) => {
    if (data && data.bag) {
      dispatch(setBag(data.bag))
    }
  })
}

export function getQuantityOfItemInBag (bag, itemId) {
  if (!bag) {
    return 0
  }

  let quantity = 0
  for (const itemType in bag) {
    quantity += bag[itemType].slots.reduce((sum, bagSlot) => {
      if (bagSlot.user_item.item.id === itemId) {
        return sum + bagSlot.quantity
      }
      return sum
    }, 0)
  }

  return quantity
}
