import ButtonLink from '@atoms/ButtonLink'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useRouter } from 'next/router'
import { Tooltip } from 'react-tippy'
import Snow from './holidays/Snow'

export default function NavListItem ({ link, iconOnly, centered }) {
  const router = useRouter()
  const routeActive = router.asPath === link.href

  return (
    <li>
      <Tooltip
        title={link.label}
        disabled={!iconOnly}
        position='bottom-end'
      >
        <ButtonLink
          className={`flex items-center relative whitespace-nowrap p-[0.5rem] rounded transition-colors my-0.5 md:my-[0.5rem] border lg:hover:bg-tertiary ${iconOnly ? 'inline-block' : 'lg:pl-[1.5rem] lg:block lg:w-52'} ${link.active || routeActive ? 'bg-primary border-transparent lg:bg-transparent lg:border-primary' : 'border-transparent'} ${centered ? 'justify-center' : ''}`}
          href={link.href}
          target={link.target}
          onClick={link.onClick}
        >
          <span className="justify-center w-full flex items-center lg:justify-start">
            <FontAwesomeIcon
              icon={link.icon}
              className={`w-[1.25rem] h-[1.25rem] inline align-middle ${iconOnly ? '' : 'mr-2'}`}
            />
            <span className={`${iconOnly ? 'hidden' : ''}`}>
              {link.label}
            </span>
            {link.iconAfter && !iconOnly && (
            <FontAwesomeIcon
              icon={link.iconAfter}
              className={`w-4 h-4 inline align-middle ${iconOnly ? '' : 'ml-2 lg:ml-auto'}`}
            />
            )}
            {link.updates && (
            <span className={`absolute right-3 bg-failure rounded-full w-3 h-3 ${iconOnly ? 'top-0' : 'top-1/2 -translate-y-1/2'}`}></span>
            )}
          </span>
          {(link.active || routeActive) && !iconOnly && <Snow width={60} />}
        </ButtonLink>
      </Tooltip>
    </li>
  )
}
