export default function Loading (props) {
  return (
    <div className="animate-pulse w-6 h-6">
      <img
        src="/tavern-flame.png"
        alt="Loading..."
        width={233}
        height={294}
      />
    </div>
  )
}
