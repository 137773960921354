import { getRarityStylesByRarityLabel } from 'consts/rarity'
import UserItemStats from './UserItemStats'
import { isZeroOrHigher } from 'helpers/numbers'
import { getEnchantingLevelLabel, getItemTypeLabel } from 'helpers/items'
import { twMerge } from 'tailwind-merge'

export default function UserItemTooltip ({ userItem }) {
  const item = userItem.item
  const rarityStyle = getRarityStylesByRarityLabel(item.rarity)
  const isGearItem = item.type === 'gear'
  const isFailStack = item.type === 'fail_stack'
  const isConsumable = item.type === 'consumable'

  return (
    <div className="text-left p-3 min-w-[300px] max-w-full">
      <h3 className="font-display text-lg">
        {item.name}
      </h3>
      <div className="flex">
        <div
          className="italic"
          style={rarityStyle.text}
        >
          {item.rarity}
        </div>
        <div className="font-bold ml-auto pl-3 capitalize text-raids-text">
          {item.gear_item && item.gear_item.slot}
          {!isGearItem && getItemTypeLabel(item)}
        </div>
      </div>
      <div className="my-3">
        {isGearItem && (
          <UserItemStats userItem={userItem} />
        )}
        {!isGearItem && item.description && item.description.length > 0 && (
          <>
            {item.description}
          </>
        )}
      </div>
      {userItem.stats && userItem.stats.quality && (
        <div className="italic capitalize">
          {userItem.stats.quality}
          {' '}
          quality
        </div>
      )}
      {isGearItem && (
        <div className={twMerge('italic', userItem.durability === 0 ? 'text-failure' : '')}>
          Durability
          {' '}
          <span className={twMerge(userItem.durability < userItem.max_durability ? 'text-failure' : '')}>
            {userItem.durability}
          </span>
          /
          {userItem.max_durability}
        </div>
      )}
      {isFailStack && (
        <div className="italic">
          Charges:
          {' '}
          {userItem.durability}
        </div>
      )}
      {item.slug === 'essence-flake' && (
        <>
          <div className="italic font-bold text-primary">
            Use: Turn 100 Flakes into 1 Essence Shard
          </div>
        </>
      )}
      {isConsumable && (
        <>
          {item.consumable_item.usage === 'account-wide-buff' && (
            <div className="italic font-bold text-primary">
              Click to use
            </div>
          )}
          {item.consumable_item.usage === 'raider' && (
            <div className="italic text-sm text-raids-text">
              Can be applied to a raider in the Armory
            </div>
          )}
          {item.consumable_item.usage === 'raid-raider' && (
            <div className="italic text-sm text-raids-text">
              Can be applied to a raider on a raid
            </div>
          )}
          {item.consumable_item.usage === 'raid' && (
            <div className="italic text-sm text-raids-text">
              Can be applied to a raid
            </div>
          )}
        </>
      )}
      {isZeroOrHigher(userItem.enchanting_level) && (
        <div className="font-bold">
          <span className="text-raids-text">
            Enchanting level
            {' '}
          </span>
          {getEnchantingLevelLabel(userItem.enchanting_category, userItem.enchanting_level)}
        </div>
      )}
      {item.source_label && item.source_label.length > 0 && (
        <div className="italic text-sm text-raids-text">
          {item.source_label}
        </div>
      )}
    </div>
  )
}
