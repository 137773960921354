import Button from '@atoms/Button'
import { faWarning } from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useDispatch, useSelector } from 'react-redux'
import { clearError } from 'redux/raids'

export default function ErrorPopup () {
  const error = useSelector(store => store.raids.error)
  const dispatch = useDispatch()

  if (!error) {
    return null
  }

  return (
    <div className="fixed lg:absolute inset-0 z-20 bg-black bg-opacity-60 flex items-center justify-center">
      <div className="flex flex-col items-center px-3 py-6 lg:p-6 text-center rounded w-96 bg-raids-darker">
        <FontAwesomeIcon
          icon={faWarning}
          className="inline-block w-24 h-24"
        />

        <div className="space-y-3">
          <h3 className="text-xl font-bold">
            {error.heading}
          </h3>

          <p>
            {error.body}
          </p>
        </div>

        <Button
          className="px-12 py-3 rounded bg-raids-primary mt-2"
          onClick={() => dispatch(clearError())}
          bounce
        >
          Dismiss
        </Button>
      </div>
    </div>
  )
}
