import Avatar from '@atoms/Avatar'
import ProgressBar from '@atoms/ProgressBar'
import UserName from '@atoms/UserName'
import { faPlus } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import BuffIcon from 'components/raids/BuffIcon'
import Link from 'next/link'
import { Tooltip } from 'react-tippy'

export default function UserBar ({ user, className, hideBuffs, usernameFixedWidth }) {
  return (
    <div className={`flex border border-primary rounded bg-dark ${className}`}>
      <div className="relative w-[60px] h-[60px] grow-0 shrink-0 rounded">
        <Link href={`/profiles/${user.discord_id}`}>
          <Avatar
            user={user}
            border={false}
          />
        </Link>

        <div className="absolute -bottom-2 -left-2 border text-lg border-primary font-display rounded-sm bg-dark flex items-center justify-center w-[28px] h-[28px]">
          <div>
            {user.level}
          </div>
        </div>
      </div>

      <div className="w-full flex flex-col">
        <div className={`relative font-normal p-2 pr-12 min-w-full max-w-full overflow-hidden whitespace-nowrap ${usernameFixedWidth ? ' w-[200px]' : ''}`}>
          {user.name
            ? (
              <UserName user={user} />
              )
            : (
              <span className="italic text-gray-300">
                Hidden profile
              </span>
              )}
          <div className="absolute top-1/2 -translate-y-1/2 translate right-0 px-2 rounded flex items-center bg-dark">
            {user && user.buffs && !hideBuffs && (
              <BuffIcon
                buffs={user.buffs}
                positionClasses="relative top-[0.125rem]"
                small={true}
              />
            )}
            {user.canCraftBuff && !hideBuffs && (
              <Tooltip title="Craft an account wide buff!">
                <Link href="/raids/craft">
                  <span className="ml-1 border bg-tertiary border-primary rounded-xs w-4 h-4 leading-0 flex items-center justify-center">
                    <FontAwesomeIcon
                      icon={faPlus}
                      className="w-3 h-3 text-white"
                    />
                  </span>
                </Link>
              </Tooltip>
            )}
          </div>
        </div>
        {user && user.levelEnd && (
          <ProgressBar
            className="w-full mt-auto"
            barStart={user?.level >= 100 ? user?.xp : user?.levelStart}
            barCurrent={user?.xp}
            barEnd={user?.level >= 100 ? user?.xp : user?.levelEnd}
            hideProgressWhenFull={true}
            barSuffix="XP"
            height="h-5"
            barOnly={true}
          />
        )}
      </div>
    </div>
  )
}
