import { useSelector } from 'react-redux'
import Avatar from '@atoms/Avatar'

export default function UserGem ({ level }) {
  const user = useSelector(store => store.main.auth.user)

  return (
    <div className="relative w-12 h-12 lg:w-[60px] lg:h-[60px]">
      <img
        src="/level-ring.png"
        alt="level gem ring"
        className="absolute inset-0 lg:hidden"
      />
      <div
        className="absolute inset-1 lg:relative lg:inset-0"
      >
        <Avatar
          user={user}
          border={false}
          rounded={true}
        />
      </div>
    </div>
  )
}
