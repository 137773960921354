import { useDispatch, useSelector } from 'react-redux'
import { setBagOpen } from 'redux/professions'
import Bag from './Bag'

export default function BagOverlay () {
  const dispatch = useDispatch()
  const { bagOpen, bagTab } = useSelector(store => store.professions)
  const handleClose = () => {
    dispatch(setBagOpen(false))
  }

  return (
    <div className="absolute bottom-32 right-8 z-30">
      {bagOpen && (
        <Bag
          bagTab={bagTab}
          handleClose={handleClose}
        />
      )}
    </div>
  )
}
