import PropTypes from 'prop-types'
import Level from '../../icons/Level'

export default function ProgressBar ({ level, label, barStart, barCurrent, barEnd, barSuffix, className, colors, height, barClass, hideProgressWhenFull }) {
  const levelProgress = barEnd - barStart > 0 ? (barCurrent - barStart) / (barEnd - barStart) : 1
  const hasLevel = Number.isInteger(level)

  return (
    <div className={`flex items-center ${className || ''}`}>
      {hasLevel && (
        <div className="relative z-10">
          <Level className={`ml-1 2-6 h-6 ${colors.text}`} />
          <span className="absolute top-0 left-0.5 w-full text-sm text-center leading-6">
            {level}
          </span>
        </div>
      )}

      <div className="relative z-6 flex-grow text-left -ml-1 lg:ml-0">
        {label && (
          <span className="absolute text-sm bottom-4 left-2">
            {label}
          </span>
        )}

        <div className={`relative ${height} overflow-hidden ${barClass || (hasLevel ? 'rounded-r-full' : 'rounded-br')}`}>
          <span className={`absolute top-0 left-0 inline-block w-full ${height} opacity-50 ${colors.bg}`}></span>
          <span
            className={`absolute top-0 left-0 inline-block ${height} ${colors.bg}`}
            style={{
              width: levelProgress * 100 + '%'
            }}
          ></span>
        </div>

        <span className="absolute text-xs top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-full text-center">
          {barCurrent}
          {(!hideProgressWhenFull || (barCurrent !== barEnd)) && (
            <>
              /
              {barEnd}
            </>
          )}
          {barSuffix && (
            <>
              {' '}
              {barSuffix}
            </>
          )}
        </span>
      </div>
    </div>
  )
}

// level, label, barStart, barCurrent, barEnd, barSuffix, className, colors, height

ProgressBar.propTypes = {
  level: PropTypes.number,
  label: PropTypes.string,
  barStart: PropTypes.number.isRequired,
  barCurrent: PropTypes.number.isRequired,
  barEnd: PropTypes.number.isRequired,
  barSuffix: PropTypes.string,
  className: PropTypes.any,
  colors: PropTypes.shape({
    text: PropTypes.string,
    bg: PropTypes.string
  }),
  height: PropTypes.string
}

ProgressBar.defaultProps = {
  className: '',
  colors: {
    text: 'text-secondary',
    bg: 'bg-secondary'
  },
  height: 'h-3'
}
