import { createAction, createReducer } from '@reduxjs/toolkit'

const initialState = {
  paymentState: 1,
  paymentData: {},
  publicNFTProject: {}
}

export const setPaymentState = createAction('mint/setPaymentState')
export const setPaymentData = createAction('mint/setPaymentData')
export const setPublicNFTProject = createAction('mint/setPublicNFTProject')

export default createReducer(initialState, {
  [setPaymentState.type]: (state, { payload }) => ({
    ...state,
    paymentState: payload
  }),
  [setPaymentData.type]: (state, { payload }) => ({
    ...state,
    paymentData: payload
  }),
  [setPublicNFTProject.type]: (state, { payload }) => ({
    ...state,
    publicNFTProject: payload
  })
})
