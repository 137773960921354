import Loading from 'icons/Loading'

export default function Crafting ({ message, small }) {
  if (!message) {
    return null
  }

  return (
    <div className="absolute inset-0 z-30 flex flex-col items-center justify-center bg-black space-y-6 bg-opacity-40">
      <Loading className={`${small ? 'w-16 h-16' : 'w-32 h-32'} animate-spin`} />

      <span>
        {message}
        &hellip;
      </span>
    </div>
  )
}
