import { Tooltip } from 'react-tippy'
import QuantityOverlay from './QuantityOverlay'
import UserItemTooltip from './items/UserItemTooltip'
import ItemTooltip from './items/ItemTooltip'

export default function ItemIcon ({ item, quantity, ownedQuantity, userItem = null, disableTooltip = false }) {
  const getImage = () => {
    if (item.image) {
      return `/items/icons/${item.image}.webp`
    }
    if (item.type === 'resource' && item.resource_item.base_material) {
      return '/professions/placeholders/raw-resource-item.png'
    }
    if (item.type === 'gear' && item.gear_item.slot === 'weapon') {
      return '/professions/placeholders/weapon.png'
    }
    if (item.type === 'gear' && item.gear_item.slot === 'special') {
      return '/professions/placeholders/tool.png'
    }
    if (item.type === 'gear' && item.gear_item.slot === 'ring') {
      return '/professions/placeholders/ring.png'
    }
    if (item.type === 'gear' && item.gear_item.slot === 'relic') {
      return '/professions/placeholders/necklace.png'
    }
    if (item.type === 'gear' && item.gear_item.slot === 'armor') {
      return '/professions/placeholders/armor-gear-item.png'
    }
    if (item.type === 'gear' && item.gear_item.slot === 'shoulders') {
      return '/professions/placeholders/shoulders-gear-item.png'
    }
    if (item.type === 'gear' && item.gear_item.slot === 'head') {
      return '/professions/placeholders/head-gear-item.png'
    }
    if (item.type === 'gear') {
      return '/professions/placeholders/gauntlets-gear-item.png'
    }

    return '/professions/placeholders/resource-item.png'
  }

  const getTooltip = () => {
    if (disableTooltip) {
      return <></>
    }

    if (userItem) {
      return <UserItemTooltip userItem={userItem} />
    }

    return <ItemTooltip item={item} />
  }

  return (
    <span className="w-full relative block">
      <Tooltip
        html={getTooltip()}
        disabled={disableTooltip}
      >
        <img
          src={getImage()}
          alt={item.name}
          className={'w-full block rounded'}
        />
      </Tooltip>

      {quantity && (
        <QuantityOverlay
          quantity={quantity}
          ownedQuantity={ownedQuantity}
        />
      )}
    </span>
  )
}
