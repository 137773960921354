import axios from 'axios'

export function getApiUri () {
  return process.env.NEXT_PUBLIC_TS_API_URI
}

const api = axios.create({
  baseURL: getApiUri()
})

api.defaults.withCredentials = true

api.interceptors.response.use(response => {
  return response
}, error => {
  if (error.response?.status === 401 && error.response?.config?.url !== '/api/user') {
    window.localStorage.setItem('redirectToUrl', window.location.pathname)
    window.location.href = getApiUri() + '/api/auth/redirect'
  }

  return Promise.reject(error)
})

export default api
